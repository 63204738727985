// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "F_vz d_bD";
export var storyRowWrapper = "F_hx d_hx d_bK";
export var storyLeftWrapper = "F_vB d_bz d_bP";
export var storyWrapperFull = "F_vC d_cD";
export var storyWrapperFullLeft = "F_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "F_mv d_hy";
export var storyLeftWrapperCenter = "F_vD d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "F_vF d_hF";
export var storyHeader = "F_vG d_hD d_w d_cs";
export var storyHeaderCenter = "F_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "F_hB d_hB d_by d_dw";
export var storyBtnWrapper = "F_vH d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "F_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "F_rj d_fg d_Z";
export var imageWrapperFull = "F_vJ d_w d_H d_bf d_Z";
export var SubtitleSmall = "F_pd z_pd z_s4 z_tg";
export var SubtitleNormal = "F_pf z_pf z_s4 z_th";
export var SubtitleLarge = "F_pg z_pg z_s4 z_tj";
export var textLeft = "F_dv";
export var textCenter = "F_dw";
export var textRight = "F_dx";