// extracted by mini-css-extract-plugin
export var alignLeft = "t_rd d_fp d_bG d_dv";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignRight = "t_rf d_fr d_bH d_dx";
export var container = "t_mw d_dW d_Z";
export var containerFull = "t_r0 d_dT d_Z";
export var title = "t_r1 d_cr";
export var text = "t_pc d_cx";
export var col = "t_r2 d_bz";
export var link = "t_mC d_w d_H";
export var card = "t_r3 d_bz d_bD d_bN d_bJ d_H d_dw d_b5 d_Z";
export var bgImage = "t_r4 d_bd d_0 d_8 d_7 d_4 d_9 d_bk";
export var overlay = "t_r5 d_bd d_0 d_8 d_7 d_4 d_9";
export var content = "t_r6 d_bz d_bD d_bJ";
export var iconBG = "t_r7 d_b7 d_bz d_bD d_bP d_cr d_cG";
export var icon = "t_n9";
export var cardTitle = "t_r8 d_w d_cs";
export var cardText = "t_r9 d_w";
export var SubtitleSmall = "t_pd z_pd z_s4 z_tg";
export var SubtitleNormal = "t_pf z_pf z_s4 z_th";
export var SubtitleLarge = "t_pg z_pg z_s4 z_tj";