// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "B_vn d_gv d_cs";
export var heroHeaderCenter = "B_gw d_gw d_cs d_dw";
export var heroHeaderRight = "B_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "B_vp d_gr d_cw";
export var heroParagraphCenter = "B_gs d_gs d_cw d_dw";
export var heroParagraphRight = "B_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "B_vq d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "B_vr d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "B_vs d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "B_vt d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "B_vv d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "B_tY z_tY";
export var heroExceptionNormal = "B_tZ z_tZ";
export var heroExceptionLarge = "B_t0 z_t0";
export var Title1Small = "B_tz z_tz z_s4 z_s5";
export var Title1Normal = "B_tB z_tB z_s4 z_s6";
export var Title1Large = "B_tC z_tC z_s4 z_s7";
export var BodySmall = "B_tP z_tP z_s4 z_tn";
export var BodyNormal = "B_tQ z_tQ z_s4 z_tp";
export var BodyLarge = "B_tR z_tR z_s4 z_tq";